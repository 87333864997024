import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import * as Api from '../apis';
import * as liff from '../apis/liff';
import MainLayout from '../components/MainLayout';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import { navigate } from 'gatsby';
import useActiveSession from '../hooks/useActiveSession';
import withAuth from '../hooks/withAuth';

const useStyles = makeStyles(theme => ({
  categoryImage: {
    padding: 10,
  },
  categoryCard: {
    padding: 10,
    textAlign: 'center',
  },
  categoryName: {
    fontSize: '16px',
    fontWeight: 'bold',
    margin: '15px',
  },
  header: {
    paddingTop: 15,
    textAlign: 'center',
  },
}));

const Categories = ({ location }) => {
  const classes = useStyles();
  const [categories, setCategories] = useState([]);

  useActiveSession(() => {
    Api.getCategories().then(data => {
      setCategories(data.results);
    });
  });

  return (
    <MainLayout>
      <h2 className={classes.header}>หมวดหมู่</h2>
      <Grid container>
        {categories.map(category => (
          <Grid
            item
            xs={6}
            sm={3}
            md={2}
            className={classes.categoryCard}
            key={category.id}
          >
            <Card>
              <CardActionArea
                onClick={() =>
                  navigate(`/consultants/?category_id=${category.id}`)
                }
              >
                <CardMedia
                  component='img'
                  image={category.image}
                  className={classes.categoryImage}
                />
                <p className={classes.categoryName}>{category.name}</p>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </MainLayout>
  );
};

export default withAuth(Categories);
